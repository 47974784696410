import { createSlice } from '@reduxjs/toolkit'

export const filterSlice = createSlice({
  name: 'filters',
  // Configure filter details 
  initialState: {
    source: '',
    exam: '',
    subject: '',
    chapters: [],
    topics: [],
    subtopics: [],
    question_type: '',
    difficulty: [],
    concepts: [],
    scenarios: []
  },
  reducers: {
    setSource: (state, action) => {
        state.source = action.payload
    },
    setExam: (state, action) => {
        state.exam = action.payload
    },
    setSubject: (state, action) => {
        state.subject = action.payload
    },
    setChapters: (state, action) => {
        state.chapters = typeof action.payload === 'string' ? action.payload.split(',') : action.payload
    },
    setTopics: (state, action) => {
        state.topics = typeof action.payload === 'string' ? action.payload.split(',') : action.payload
    },
    setSubtopics: (state, action) => {
        state.subtopics = typeof action.payload === 'string' ? action.payload.split(',') : action.payload
    },
    setQuestionType: (state, action) => {
        state.question_type = action.payload
    },
    setDifficulty: (state, action) => {
        state.difficulty = typeof action.payload === 'string' ? action.payload.split(',') : action.payload
    },
    setConcepts: (state, action) => {
        state.concepts = typeof action.payload === 'string' ? action.payload.split(',') : action.payload
    },
    setScenarios: (state, action) => {
        state.scenarios = typeof action.payload === 'string' ? action.payload.split(',') : action.payload
    },
  },
})

export const { setSource, setExam, setSubject, setChapters, setTopics, setSubtopics, setQuestionType, setDifficulty, setConcepts, setScenarios } = filterSlice.actions

export default filterSlice.reducer
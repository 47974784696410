import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <div>
      <h1>Looks like our curious explorer has lost their way</h1>
      <Link onClick={()=>{navigate('/', {replace: true})}}>Go Back Home</Link>
    </div>
  )
}

export default NotFound

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Options from "./problemStatementComponents/Options";
import Question from "./problemStatementComponents/Question";
import AnswerKey from "./problemStatementComponents/AnswerKey";
import Solution from "./problemStatementComponents/Solution";

import { addQuizQuestion, removeQuizQuestion } from '../store/slice/quizSlice';
import { rejectQuestion } from "../store/slice/rejectSlice";
import { Grid, Typography } from "@mui/material";
import ErrorBoundary from "./ErrorBoundary";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const ProblemStatement = ({
    id, 
    data,
    displayQuestion,
    displayQuestionId,
    displaySolution,
    displayAnswerKey,
    displayOptions,
    displayAddToQuiz,
    displayRemoveFromQuiz,
    displayRejectQuestion,
    removeFromQuizDetails,
    removeQuizDetails,
    setOpenQuizModal,
    displayConcepts,
    index
}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const dispatch = useDispatch();
    const quizQuestions = useSelector((state) => state.quiz.questions);
    const activeQuiz = useSelector((state) => state.quiz.activeQuiz);

    const handleRejectQuestion = (id) => {
        dispatch(rejectQuestion(id));
    }

    return (
        <div style={{width:'100%'}} className="problemContainer">
            {displayQuestionId && (
                <div className="questionHeader">
                    <div>
                        <b>ID : {id}</b>
                    </div>
                    <div>
                        <b>Difficulty: {data.difficulty}</b>
                    </div>
                </div>
            )}
            <div style={{width:'100%'}}>
                <Grid container>
                    <Grid item md={data.concepts && displayConcepts ? 8 : 12}>
                        {data.paragraph_image !== '' ? (
                            <ParseParagraph paragraph_image={data.paragraph_image} childIds={data.child_id} id={id} />
                        ) : (
                            <ErrorBoundary>
                                <Question data={data} index={index} />
                            </ErrorBoundary>
                        )}
                        <Options options={data.options} id={id} />
                        {data.paragraph_image === '' ? (
                            displayAnswerKey &&
                            <AnswerKey answerKey={data.answer_key} questionType={data.question_type} source={data.source} id={id} />
                        ) : (
                            <span></span>
                        )}
                    </Grid>
                    
                    <Grid item md={4}>
                        {data.concepts && displayConcepts && (
                            <Box  style={{borderLeft: '1px solid lightgray', paddingLeft: '1vw', marginBottom: '1vh'}}>
                                <b>Concepts</b>
                                {data.concepts.split(';').slice(0, 30).map((tag, key) => (
                                    <Typography key={key} >
                                        <li>{tag}</li>
                                    </Typography>
                                ))}
                            </Box>
                        )}
                        {data.context && displayConcepts && (
                            <Box sx={{borderLeft: '1px solid lightgray', paddingLeft: '1vw', marginTop: '2vh', paddingTop: '1vh', marginBottom: '2vh'}}>
                                <b>Context</b>
                                {data.context.split(';').slice(0, 30).map((tag, key) => (
                                    <Typography key={key} >
                                        <li>{tag}</li>
                                    </Typography>
                                ))}
                            </Box>
                        )}
                    </Grid>
                </Grid>
                
                <Stack spacing={2} direction="row" className='buttonGroup'>
                    {displaySolution && <Button variant="contained" onClick={handleOpen}>Solution</Button>}
                    {quizQuestions.includes(id) ? (
                        <span>
                            {displayRemoveFromQuiz && !activeQuiz.saved && (
                                removeQuizDetails ? (
                                    <Button color="primary" variant="contained" onClick={() => removeFromQuizDetails(id)}>Remove From Quiz</Button>
                                ) : (
                                    <Button color="primary" variant="contained" onClick={() => dispatch(removeQuizQuestion(id))}>Remove From Quiz</Button>
                                )
                            )}
                        </span>
                    ) : (
                        <span>
                            <Stack spacing={2} direction="row"> 
                            {displayAddToQuiz && (
                                Object.keys(activeQuiz).length > 1 ? (
                                    <Button color="primary" variant="outlined" onClick={() => dispatch(addQuizQuestion(data))}>Add To Quiz</Button>
                                ) : (
                                    <Button color="primary" variant="outlined" onClick={() => setOpenQuizModal(true)}>Add To Quiz</Button>
                                )
                            )}
                            <span>
                            {
                                displayRejectQuestion &&
                                <Button color="primary" variant="contained" onClick={() => handleRejectQuestion(id)}>Reject Question</Button>
                            }
                            </span>
                            </Stack>
                        </span>
                    )}
                </Stack>
                <Modal open={open} onClose={handleClose}>
                    <Box sx={style} style={{ overflow: 'scroll', height: '80vh', width: '60vw' }}>
                        <Solution data={data} id={id} />
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

const ParseParagraph = ({ paragraph_image, childIds, id }) => {
    return (
        <div>
            <img src={paragraph_image} alt={paragraph_image} />
        </div>
    );
};

export default ProblemStatement;

import { useEffect } from "react"

const Solution = ({data, id}) => {
    
    useEffect(()=>{
        if(typeof window?.MathJax !== "undefined"){
          window.MathJax.typesetClear()
          window.MathJax.typeset()
        }
      },[])

    const parseLatexString = (latexString) => {
        let firstDegreeElements = latexString.split(/\$\r\n\$|\$\n\$/)
        
        let firstDegreeParsing = []
        for (let element in firstDegreeElements) {
            let subString = firstDegreeElements[element]

            if (String(element) !== '0') {
                subString = (subString.charAt(0) === '$' ? '' : '$') + subString
            }
            if (String(element) !== String(firstDegreeElements.length - 1))
            subString = subString + (subString.slice(-1) === '$' ? '' : '$')

            firstDegreeParsing = firstDegreeParsing.concat(subString) 
        }

        let secondDegreeParsing = []
        for (let element in firstDegreeParsing) {
            const subElements = firstDegreeParsing[element].split(/\$\r\n|\$\n/)
            
            let parsedSubElements = subElements.map((latex, key) => latex + ((key !== (subElements.length - 1) && latex.slice(-1) !== '$') ? '$' : ''))
            
            if (subElements[0].trim() === '') {
                parsedSubElements[1] = '$' + parsedSubElements[1]
            }

            secondDegreeParsing = secondDegreeParsing.concat(parsedSubElements)
        }
        
        let thirdDegreeParsing = []
        for (let element in secondDegreeParsing) {
            const subElements = secondDegreeParsing[element].split(/\r\n\$|\n\$/)
            
            let parsedSubElements = subElements.map((latex, key) => ((key !== 0) && latex.charAt(0) !== '$' ? '$' : '') + latex)

            if (subElements[subElements.length - 1].trim() === '')
                parsedSubElements[subElements.length - 2] = parsedSubElements[subElements.length - 2] + '$'
            
            thirdDegreeParsing = thirdDegreeParsing.concat(parsedSubElements)
        }

        let fourthDegreeParsing = []
        for (let element in thirdDegreeParsing) {
    
            let subElements = thirdDegreeParsing[element]

            if (subElements === '$') subElements = ''
    
            fourthDegreeParsing = fourthDegreeParsing.concat(subElements)
        }
        
        let fifthDegreeParsing = []
        for (let element in fourthDegreeParsing) {
            let subString = fourthDegreeParsing[element]
            
            if ((subString.indexOf("$") === 0) && (subString.indexOf("$", 1) !== (subString.length - 1)) && (subString.slice(-1) === '$')) {
                subString = "$" + subString.replaceAll('$', '') + "$"
            }
            
            if ((subString.split("$").length === 2) && (subString.charAt(0) !== '$') && (subString.slice(-1) === '$')) {
                subString = "$" + subString.replace(/\$/g, '') + "$"
            }

            if ((subString.split("$").length === 2) && (subString.charAt(0) === '$') && (subString.slice(-1) !== '$')) {
                subString = "$" + subString.replace(/\$/g, '') + "$"
            }

            if ((subString.split("$").length % 2 === 0) && (subString.charAt(0) !== '$') && (subString.slice(-1) === '$')) {
                subString =  subString.replaceAll('$', '') 
            }

            else if ((subString.split("$").length % 2 === 0) && (subString.charAt(0) === '$') && (subString.slice(-1) !== '$')) {
                subString =  subString.replaceAll('$', '') 
            }

            fifthDegreeParsing = fifthDegreeParsing.concat(subString)
        }

        return fifthDegreeParsing
    }    
    
    return (
        <div style={{width:"100%",height:'100%'}} className="solutionItem">
            <span>
                <b>Solution</b>
            </span>
            {
                data.solution_latex === '' ?
                <div style={{width:'100%',height:'100%'}} className="solutionContent">
                    <img style={{width:'100%',height:'100%', objectFit:'contain'}}
                        src={data.solution_image}
                        alt={data.solution_image}
                    />
                </div> 
                :
                <div className="solutionContent">
                    {parseLatexString(data.solution_latex).map((latex, key) =>
                        <p key={key}>{latex}</p>
                    )}
                </div>
            }
        </div>
    )
}

export default Solution
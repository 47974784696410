import { FormControl } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  }

const SingleSelectInput = ({selectedValue, handleChange, options, valueKey}) => {
    return (
        <span>
            <FormControl size="small"> 
             <Select
                id='select'
                value={selectedValue}
                onChange={handleChange}
                MenuProps={MenuProps}
                >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                    {
                        options.map((option) => (
                            <MenuItem key={option.name} value={valueKey === 'id' ? option.id: option.name}>{option.name}</MenuItem>
                        ))
                    }
            </Select>
            </FormControl>
        </span>
    )
}

export default SingleSelectInput
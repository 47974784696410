import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { FormControl } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  }

const MultiSelectInput = ({selectedValues, handleChange, options, valueKey}) => {    
    
    return (
        <span>
            <FormControl size='small'>
            <Select
                id="select"
                multiple
                value={selectedValues}
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
            >
                {options.map((option) => (
                    <MenuItem key={option.name} value={valueKey === 'id' ? option.id: option.name}>
                        <Checkbox checked={selectedValues.indexOf(valueKey === 'id' ? option.id: option.name) > -1} />
                        <ListItemText primary={option.name} />
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
        </span>
    )
}

export default MultiSelectInput
import axios from "axios"

import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import { Button, CircularProgress, Divider, Grid, Paper, Toolbar, Typography } from "@mui/material"

import ProblemStatement from "../../components/ProblemStatement"
import Solution from "../../components/problemStatementComponents/Solution"
import FixedSideNavigation from "../../components/FixedSideNavigation"

import { removeQuizQuestion, saveActiveQuiz } from "../../store/slice/quizSlice"
import { fetchQuestionById } from "../../utils/question"
import { fetchQuizByStudentID } from "../../utils/quiz"
import { useNavigate } from "react-router-dom"

const QuizCreator = () => { 
    const [value, setValue] = useState(0)
    const [message, setMessage] = useState({})
    const [studentQuizzes, setStudentQuizzes] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const quizQuestions = useSelector((state) => state.quiz.questions)
    const token = useSelector((state) => state.user.accessToken)
    const activeQuiz = useSelector((state) => state.quiz.activeQuiz)

    const time = activeQuiz.quizTime;

    const [questionDetails, setQuestionDetails] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      const fetchQuestions = async () => {
        const fetchedDetails = await Promise.all(
          quizQuestions.map((id) => fetchQuestionById({id, token}))
        )

        setQuestionDetails(fetchedDetails)

        setIsLoading(false)
      }

      if (isLoading) {
        fetchQuestions()
      }
    }, [quizQuestions, isLoading, token])
  
    const uniqueChapter = [...new Set(questionDetails.flatMap(item => item.chapter))];
    const uniqueDifficulty = [...new Set(questionDetails.flatMap(item => item.difficulty))];
    const uniqueTopic = [...new Set(questionDetails.flatMap(item => item.topic))];
    const uniqueSubject = [...new Set(questionDetails.flatMap(item => item.subject))];

    const removeFromQuizDetails = (questionId) => {
      const details = questionDetails
      setQuestionDetails(details.filter(ques => ques.id !== questionId))
    
      dispatch(removeQuizQuestion(questionId))
    }

    useEffect(() => { 
      try {
        const fetchStudentsQuiz = async (studentIds) => {
          const response = await fetchQuizByStudentID(token, studentIds)
          setStudentQuizzes(response)

        }

        if (activeQuiz.studentId && activeQuiz.studentId.length > 0)
          fetchStudentsQuiz(activeQuiz.studentId)

      } catch (error) {
        console.log(error)
      }

      
    }, [activeQuiz, token])

    useEffect(() => {
      if (studentQuizzes.length > 0) {
        const testedQuestions = {}
        const studentNames = activeQuiz.studentName
        const studentIds = activeQuiz.studentId

        studentQuizzes.map(quiz => {
          if (studentIds.includes(quiz.student_id)){
            const intersection = quizQuestions.filter(value => quiz.questions.includes(value))
            
            if (!Object.keys(testedQuestions).includes(quiz.student_name)) {
              testedQuestions[quiz.student_name] = {}
            }

            if (intersection.length > 0) {
              if (Object.keys(testedQuestions).includes(quiz.student_name) && Object.keys(testedQuestions[quiz.student_name]).includes(quiz.name))
                testedQuestions[quiz.student_name][quiz.name] = testedQuestions[quiz.student_name][quiz.name].concat(intersection)
              else
                testedQuestions[quiz.student_name][quiz.name] = intersection
            }
          }
          return {}
        })
        
        studentNames.map((name) => {
          return Object.values(testedQuestions[name]).map((quiz) => {
            testedQuestions[name][quiz] = [...new Set(testedQuestions[name][quiz])]
            return {}
          })
        })
        
        setMessage(testedQuestions)
      }
    }, [quizQuestions, studentQuizzes, activeQuiz])

    const saveQuiz = async () => {
      try {
          const data = {
            quizName: activeQuiz.quizName, 
            studentId: activeQuiz.studentId,
            questions: quizQuestions,
            difficulty : uniqueDifficulty, //[]
            topic : uniqueTopic, //[]
            chapter : uniqueChapter, //[]
            subject : uniqueSubject, //[]
            max_attempt_time_in_minutes : parseInt(time)
          }
      
          const header = {
            Authorization: `Bearer ${token}`
          }

          const response = await axios.post('/api/quiz/create', data, {
            headers: header
          })

          dispatch(saveActiveQuiz({quizId: response.data.quiz.id}))
          navigate(`/view-quiz/${response.data.quiz.id}`)
      } catch (e){
        console.log("SOME ERROR OCCURED", e)
      }
    }

    if (isLoading) {
      return <Box sx={{
        display: 'flex',
        justifyContent:'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
          <Toolbar />
          <Toolbar />
          <CircularProgress/>
        </Box>
    }

    return (
      <Box sx={{display: 'flex'}}>
        <FixedSideNavigation 
              value={value}
              setValue={setValue}
        />
        <Grid 
            container
            component="main"
            sx={{
              padding: '2vh',
              flexGrow: 0, 
              width:'80vw',
              p: 3
            }}
        >
          <Toolbar />
          <Grid 
            container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Grid item md={5} sm={8} xs={12}>
              <Box>
                {
                  Object.keys(activeQuiz).length > 1 &&
                    <Box sx={{
                      width: '100%',
                    }}>
                      <Box sx={{fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{activeQuiz.quizName}</Box>
                      <Box sx={{fontSize: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{quizQuestions.length} Questions</Box>
                      
                      <Box sx={{marginTop: '1vh', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap:'5px'}}>
                        {
                          !activeQuiz.saved && quizQuestions.length > 0 &&
                            <Button variant='outlined' onClick={() => saveQuiz()}>Save Quiz</Button>
                        }
                        <Button variant='outlined'>Delete Quiz</Button>
                      </Box>
                    </Box>
                }
              </Box>
            </Grid>
          </Grid>

          <Grid 
            container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {
              value === 0 && 
              <Grid item md={8} sm={8} xs={12}>
                <Box sx={{marginTop: '2vh', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                  <Typography>{activeQuiz.studentName.join(', ')}</Typography>
                </Box>
                {
                  questionDetails.map((questionData, index) => (
                  <Box key={`${questionData['id']}-question-selected`}>
                    <ProblemStatement 
                      id={questionData['id']}
                      data={questionData}
                      key={questionData['id']}
                      displayQuestion={true}
                      displayQuestionId={true}
                      displaySolution={true}
                      displayAnswerKey={true}
                      displayOptions={true}
                      displayAddToQuiz={false}
                      displayRemoveFromQuiz={true}
                      removeQuizDetails={true}
                      displayConcepts={true}
                      removeFromQuizDetails={removeFromQuizDetails}
                      index = {index + 1}
                    />
                    {
                      !activeQuiz.saved &&
                      <Box sx={{
                        marginBottom: '1vh',
                        width: '100%', 
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                        {
                          Object.entries(message).length > 0 &&
                            Object.entries(message).map(([name, quizzes]) => (
                              Object.entries(quizzes).map(([quizname, questions], index) => (
                                questions.includes(questionData['id']) &&
                                <Paper 
                                  key={index}
                                  elevation={0}
                                  sx={{
                                    paddingLeft: '2vh',
                                    paddingBottom: '1vh',
                                    paddingTop: '1vh',
                                    borderRadius: 0,
                                  }}
                                >
                                  <Typography variant="body2">
                                    <span key={index}>This question has already been tested for: <b>{name}</b> in quiz <b>{quizname}</b></span>
                                  </Typography>
                                </Paper>
                              ))
                          ))
                        }
                      </Box>
                    }
                    <Divider style={{backgroundColor: 'black'}} />
                  </Box>
                ))
              }
              </Grid>
            }
            {
              value === 1 &&
              <Grid item md={5} sm={8} xs={12}>
                <span>SUMMARY</span>
              </Grid>
            }

            {
              value === 2 && 
              <Grid item md={5} sm={8} xs={12}>
              {
                questionDetails.map((questionData, index) => (
                  <Box key={`${questionData['id']}-solution-selected`}>
                    <ProblemStatement 
                        id={questionData['id']}
                        data={questionData}
                        key={`${questionData['id']}-question`}
                        displayQuestion={true}
                        displayQuestionId={false}
                        displaySolution={false}
                        displayAnswerKey={true}
                        displayOptions={false}
                        displayAddToQuiz={false}
                        displayRemoveFromQuiz={false}
                        removeQuizDetails={false}
                        displayConcepts={false}
                        removeFromQuizDetails={removeFromQuizDetails}
                        index = {index + 1}
                      />
                    <Solution key={questionData['id']} data={questionData} id={questionData['id']} />
                    <Divider style={{backgroundColor: 'black'}}/>
                  </Box>
                ))
              }
              </Grid>
            }
          </Grid> 
      </Grid>
    </Box>
  )
}

export default QuizCreator
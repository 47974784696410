import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { Box, Typography, IconButton, Modal, Button } from "@mui/material"
import { FormGroup, ListItem, ListItemButton, ListItemText, Stack, TextField } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'

import { removeQuizQuestion, clearSelection, addActiveQuiz, bulkUpdateSelection, removeActiveQuiz, addQuizTime } from '../store/slice/quizSlice'

import { fetchQuizList } from '../utils/quiz'
import { fetchStudentList } from '../utils/student'
import MultiSelectInput from './selectComponents/multiSelectInput'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
}


const QuizSelector = ({openQuizModal, setOpenQuizModal}) => {    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Redux store to save selected questions
    const quizQuestions = useSelector((state) => state.quiz.questions)
    // Redux store to save active quiz
    const activeQuiz = useSelector((state) => state.quiz.activeQuiz)
    const token = useSelector((state) => state.user.accessToken)

    // Modal config
    const [openQuizListModel, setOpenQuizListModel] = useState(false)
    const [quizList, setQuizList] = useState([])
    // State to store quiz details
    const [quizName, setQuizName] = useState('')
    const [studentName, setStudentName] = useState([])
    const [studentId, setStudentId] = useState([])
    const [quizQuestionList, setQuizQuestionList] = useState({})
    const [students, setStudents] = useState([])
    const [studentNameFilter,setStudentNameFilter] = useState('')
    const [filteredQuizList, setFilteredQuizList] = useState([]);
    const [uniqueStudentNames,setUniqueStudentNames] = useState([])
    const [time,setTime] = useState()
    
    const handleOpen = () => setOpenQuizModal(true)
    const handleClose = () => setOpenQuizModal(false)

    useEffect(() => {
        const fetchStudents = async () => {
            const students = await fetchStudentList(token)
            setStudents(students)
        }
        fetchStudents()
    }, [token])

    useEffect(() => {
        const uniqueStudent = [...new Set(quizList.flatMap(item => item.student_name))];
        setUniqueStudentNames(uniqueStudent)
        
        let filteredList = quizList;
    
        if (studentNameFilter && studentNameFilter !== 'All') {
            filteredList = filteredList.filter(quiz => quiz.student_name.includes(studentNameFilter));
        }
    
        setFilteredQuizList(filteredList);
    }, [studentNameFilter, quizList]);

    const handleQuizListOpen = async () => {
        // Fetch list of quizzez to choose from
        const quizList = await fetchQuizList(token)
        setQuizList(quizList)
        setOpenQuizListModel(true)
    }

    const handleQuizListClose = () =>{
        setQuizList([])
        setOpenQuizListModel(false)
    }

    const createQuiz =  () => {
        if (!studentName || studentName.length === 0) {
            alert("Please enter student name")
        } else if (!quizName){
            alert("Please enter quiz name")
        }else if(!time){
            alert("Please Enter Quiz Time")
        }
         else {
            // Save quiz details in redux store
            dispatch(addActiveQuiz({
                quizName: quizName,
                studentName: studentName,
                studentId: studentId
            }))
            dispatch(addQuizTime(time))
            dispatch(clearSelection({}))

            if (Object.keys(quizQuestionList).length > 0) {
                // If importing quiz then save questions in redux store
                dispatch(bulkUpdateSelection(quizQuestionList))
                setQuizQuestionList({})
            }

            handleClose()
        }
    }

    const importQuiz =  (quiz) => {
        // Save quiz config while importing quiz
        setQuizName(quiz.name)
        setStudentName([])
        setQuizQuestionList(quiz.questions)

        handleQuizListClose()
        // Move to quiz config modal
        handleOpen()
    }

    const clearQuiz = () => {
        // Reset states
        setQuizName('')
        setStudentName([])
        setStudentId([])
        dispatch(clearSelection({}))
        dispatch(removeActiveQuiz())
    }

    return (
        <Box sx={{
            height: '100%',
            margin: '1vw'
        }}>
            {
                Object.keys(activeQuiz).length > 1 && !activeQuiz.saved ?
                // Display quiz details
                <Box>
                    <Typography><b>Quiz Name:</b> {activeQuiz.quizName}</Typography>
                    <Typography><b>Student Name:</b> {activeQuiz.studentName.join(', ')}</Typography>
                    <p></p>
                    {quizQuestions.length > 0 &&
                    <div>
                        <div>
                        <span><b>{quizQuestions.length} Questions Selected</b></span>
                        </div>
                        {
                            (quizQuestions).map((id) => (
                                <div key={`selectedQuestion-${id}`}>
                                    <span>
                                        <IconButton aria-label="delete" onClick={() => dispatch(removeQuizQuestion(id))}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </span>
                                    <span><b>{id}</b></span>
                                </div>  
                            ))
                        }
                        <Stack>
                            <Button onClick={() => navigate('/review-quiz')}>Review Quiz</Button>
                            <Button onClick={()=> dispatch(clearSelection({}))}>Clear Selection</Button>
                        </Stack>
                        
                    </div> 
                    }
                    <Stack>
                        <Button onClick={clearQuiz}>Delete Quiz</Button>
                    </Stack>
                    
                </Box>    
                :
                // Create Quiz Component If Quiz Not Selected
                <Stack direction={'column'}>
                    <Button variant='outlined' onClick={handleOpen} style={{margin: '1vh', width: '95%'}}>Create New Quiz</Button>
                    <Button variant='outlined' onClick={handleQuizListOpen} style={{margin: '1vh', width: '95%'}}>Import Existing Quiz</Button>
                </Stack>
            }


            {/* Create Quiz Model */}
            <Modal
                open={openQuizModal}
                onClose={handleClose}
            >
                <Box sx={style} style={{ overflow: 'scroll', height: '80vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography align='center'>
                        Enter quiz details
                    </Typography>
    
                    <FormGroup style={{width: '100%',justifyContent: 'center', alignItems: 'center',flexDirection: 'column',padding: '2vh 0' }}>
                        <TextField 
                            style={{ width: '90%', margin: '1vh 0' }} 
                            required 
                            id="outlined-basic" 
                            label="Enter Quiz Name" 
                            value={quizName}
                            onChange={(event) => {
                                setQuizName(event.target.value)
                            }}
                            variant="outlined" 
                        />

                        <div style={{ width: '90%', margin: '1vh 0' }}>
                            <Typography>Select Student Name</Typography>
                            <MultiSelectInput 
                                selectedValues={studentName}
                                handleChange={(event) => {
                                    const { target: { value } } = event;
                                    setStudentName(value);
                                    
                                    const selected_student = students.filter(student => 
                                        value.includes(student.name)
                                    );
                                    
                                    if (selected_student.length > 0) {
                                        const studentIdArrays = selected_student.map(item => item.id);
                                        setStudentId(studentIdArrays);
                                    } else {
                                        setStudentId([]);
                                    }
                                }}
                                options={students}
                                valueKey='name'
                            />
                        </div>

                        <div style={{ width: '90%', margin: '1vh 0' }}>
                            <Typography style={{ marginTop: "2vh" }}>Enter Quiz Time in Minutes</Typography>
                            <TextField 
                                style={{ width: '40%', margin: '1vh 0' }} 
                                required 
                                id="outlined-basic" 
                                label="Time"
                                value={time}
                                onChange={(event) => {
                                    setTime(event.target.value)
                                }}
                                variant="outlined" 
                            />
                        </div>

                        <Button style={{ margin: "5vh" }} onClick={createQuiz} variant='contained'>
                            Create Quiz
                        </Button>
                    </FormGroup>
                </Box>

            </Modal>

            {/* Import Quiz Modal */}
            <Modal
                open={openQuizListModel}
                onClose={handleQuizListClose}
            >
                <Box sx={style} style={{overflow: 'scroll', height: '80vh'}}>
                    <Typography align='center'>
                        Select Quiz
                    </Typography>
                    <div  style={{marginBottom: '10px',padding: '10px',border: '1px solid #ccc',borderRadius: '4px',display: 'flex',flexDirection: 'column'}}>
                        <ListItem component="div" disablePadding>
                            <ListItemButton style={{display: 'flex', justifyContent: 'space-between',alignItems: 'center', width: '100%' }}>
                                <div style={{ flex: 1 }}>
                                    <ListItemText primary={`Quiz Name `} primaryTypographyProps={{ style: {color:'blue', width: '100%' } }}/>
                                        </div>
                                        <div style={{ flex: 0.5 }}>
                                            <ListItemText primary={`Student Name`} primaryTypographyProps={{ style: {color:'blue', width: '100%' } }}/>
                                        </div>
                                        <div style={{ flexShrink: 0 }}>
                                            <ListItemText primary={`Questions`} primaryTypographyProps={{ style: {color:'blue', textAlign: 'right' } }} />
                                        </div>
                                    </ListItemButton>
                                </ListItem>
                    </div>

                    <div style={{display: 'flex',justifyContent:'center',alignItems:'center',margin:'20px'}}>
                        <label htmlFor="studentNameFilter" style={{ marginRight: '10px' }}>Filter by Student Name</label>
                        <select
                            id="studentNameFilter"
                            value={studentNameFilter}
                            onChange={(e) => {
                                setStudentNameFilter(e.target.value);
                            }}
                            style={{ padding: '10px', borderRadius: '4px' }}
                        >
                            <option value="">All</option>
                                {uniqueStudentNames.map((name) => (
                                    <option key={name} value={name}>{name}</option>
                            ))}
                        </select>
                    </div>

                    
                    {
                         filteredQuizList.map((quiz, index) => (
                            <div key={quiz.id} style={{
                                    marginBottom: '10px',
                                    padding: '10px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >                           
                                <ListItem component="div" disablePadding>
                                    <ListItemButton
                                        onClick={() => importQuiz(quiz)}
                                        style={{display: 'flex', justifyContent: 'space-between',alignItems: 'center', width: '100%' }}
                                    >
                                        <div style={{ flex: 1 }}>
                                            <ListItemText
                                                primary={`${index + 1}. ${quiz.name} `}
                                                primaryTypographyProps={{ style: { width: '100%' } }} 
                                            />
                                        </div>
                                        <div style={{ flex: 0.5 }}>
                                            <ListItemText
                                                primary={`${quiz.student_name}`}
                                                primaryTypographyProps={{ style: { width: '100%' } }} 
                                            />
                                        </div>
                                        <div style={{ flexShrink: 0 }}>
                                            <ListItemText
                                                primary={`${Object.keys(quiz.questions).length} questions`}
                                                primaryTypographyProps={{ style: { textAlign: 'right' } }} 
                                            />
                                        </div>
                                    </ListItemButton>
                                </ListItem>
                            </div>
                        ))  
                    }
                </Box>
            </Modal>
        </Box>
    )
}

export default QuizSelector
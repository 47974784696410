import { Box } from "@mui/material";
import { useState } from "react";

const Pagination = ({ fetchQuestions, page, limit, total }) => {

    const [pageNo,setPageNo] = useState()


    const handleChange =()=>{
        if(pageNo>Math.ceil(total / limit)){
            alert("Invalid Page No ")
            setPageNo((''))
            return
        }

        fetchQuestions(pageNo)
        setPageNo("")
    }

    return (
        <Box>
            <span className='pagination'>
                <button onClick={() => fetchQuestions(page - 1)} disabled={page === 1}>
                    Previous
                </button>
                <button onClick={() => fetchQuestions(page + 1)} disabled={page * limit >= total}>
                    Next
                </button>
                <p>Page {page} of {Math.ceil(total / limit)}</p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label>Jump To Page No:</label>
                &nbsp;
                <input type="number" onChange={(e)=> setPageNo(e.target.value)} value={pageNo} min={1} max={limit} style={{ width: '60px', marginLeft: '8px', padding: '4px', borderRadius: '4px', border: '1px solid #ccc' }} />
                <button onClick={handleChange}>Go</button>
            </span>
        </Box>
    )
}
export default Pagination

import { useEffect } from 'react'

function Quiz() {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script')
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js'
    
    // Set the script source to the remote-widgets.js file
    script.src = widgetScriptSrc
    script.async = true

    // Append the script to the document body
    document.body.appendChild(script)

    script.onload = () => {
      window.Tally.loadEmbeds()
    }

    const onMessage = (event) => {
      console.log(event)
    }

    const onFocus = (event) => {
      console.log("FOCUS")
    }
    
    const onBlur = (event) => {
      console.log("BLUR")
    }

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    window.addEventListener('message', onMessage)

    // Cleanup: Remove the script elements when the component unmounts
    return () => {
      document.body.removeChild(script)
      window.removeEventListener('message', onMessage)
    }
  }, [])

  return (
    <div>
        <iframe
          data-tally-src="https://tally.so/r/mRLx7p?transparentBackground=1"
          loading="lazy"
          title='quiz'
        ></iframe>
    </div>
  );
}

export default Quiz

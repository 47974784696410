import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { RouterProvider } from 'react-router-dom'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

import { store, persistor } from './store'
import { router } from './routes'

// import reportWebVitals from './reportWebVitals'

// Configure theme for the app.
const theme = createTheme({
  palette: {
    mode: 'light',
    text: {
      primary: '#0A0A0A',
      secondary: '#0A0A0A',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F5F5F5',
    },
    primary: {
      light: '#6469F7',
      main: '#090FAE',
      dark: '#080B88',
      contrastText: '#fff'
    },
    secondary:  {
      light: '#FFFFFF',
      main: '#FFFFFF',
      dark: '#F5F5F5',
      contrastText: '#0A0A0A'
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Stack from '@mui/material/Stack'
import { Box, Toolbar } from '@mui/material'

import { setAccessToken, setProfile } from '../store/slice/userSlice'
import { clearSelection, removeActiveQuiz } from '../store/slice/quizSlice'
import { setChapters, setConcepts, setDifficulty, setExam, setQuestionType, setScenarios, setSource, setSubject, setSubtopics, setTopics } from '../store/slice/filterSlice'

const Home = () => {
  const navigate = useNavigate()  
  const dispatch = useDispatch()

  const profile = useSelector((state) => state.user.profile)

  const logOut = () => {
    // Reset redux store on logout
    dispatch(setProfile({}))
    dispatch(setAccessToken(null))
    dispatch(clearSelection({}))
    dispatch(removeActiveQuiz())

    dispatch(setExam(''))
    dispatch(setSubject(''))
    dispatch(setChapters([]))
    dispatch(setTopics([]))
    dispatch(setSubtopics([]))
    dispatch(setSource(''))
    dispatch(setQuestionType(''))
    dispatch(setDifficulty([]))
    dispatch(setConcepts([]))
    dispatch(setScenarios([]))
    navigate('/')
  }

  return (
    <div>
      {/* Setup App Bar For App */}
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, 
        backgroundColor: (theme) => theme.palette.secondary.dark, 
        borderBottom: '1px solid lightgrey'}} elevation={0}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
              <Stack spacing={2} direction="row">
                <Button variant="text" onClick={() => navigate('/')}>Question Manager</Button>
                <Button variant="text" onClick={() => navigate('/quizzes')}>Quizzes</Button>
              </Stack>
            </Box>
            <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
              <img src="../lytmus-background.png" alt="logo" width={75} height={50} />
            </Box>
            <Box sx={{ flexGrow: 0, display: { md: 'flex' }}}>
              <Stack spacing={2} direction="row">
                <Button variant="text" disabled>{profile.name}</Button>
                <Button variant="text" onClick={logOut}>Logout</Button>
              </Stack>
            </Box>
          </Toolbar>
      </AppBar>

      {/* Render Children Routes */}
      <Outlet />
    </div>
  )
}

export default Home
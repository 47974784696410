import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { CircularProgress } from '@mui/material'

import { setAccessToken } from '../store/slice/userSlice'

const ProcessLogin = () => {
    const [ searchParams ] = useSearchParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        // Get token from url params
        const token = searchParams.get('token')
        if (token) {
            // Add token to redux store
            dispatch(setAccessToken(token))
            // Go to homepage
            navigate('/')
        } else {
            navigate('/invalid')
        }
    }, [searchParams])

    return (
        <div>
            <CircularProgress />
        </div>
    )
}

export default ProcessLogin
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { Box, CircularProgress, Divider, Drawer, Grid, Toolbar } from '@mui/material';
import Button from '@mui/material/Button';

import { setChapters, setConcepts, setDifficulty, setExam, setQuestionType, setScenarios, setSource, setSubject, setSubtopics, setTopics } from '../../store/slice/filterSlice';
import { removeAllRejectedQuestions } from '../../store/slice/rejectSlice';

import StaticFilterComponent from '../../components/StaticFilter';
import ProblemStatement from '../../components/ProblemStatement';
import Pagination from '../../components/Pagination';
import QuizSelector from '../../components/QuizSelector';
// import { uploadQuestionsToDrive } from '../../utils/question';
import { clearSelection, removeActiveQuiz } from '../../store/slice/quizSlice';

const QuestionManager = () => {
    const dispatch = useDispatch();

    const token = useSelector((state) => state.user.accessToken);
    const activeQuiz = useSelector((state) => state.quiz.activeQuiz);

    const [questions, setQuestions] = useState([]);
    const [openQuizModal, setOpenQuizModal] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isFilterLoading, setIsFilterLoading] = useState(false);

    const limit = 20;
    
    const selectedExam = useSelector((state) => state.filters.exam);
    const selectedSubject = useSelector((state) => state.filters.subject);
    const selectedChapter = useSelector((state) => state.filters.chapters);
    const selectedTopic = useSelector((state) => state.filters.topics);
    const selectedSource = useSelector((state) => state.filters.source);
    const selectedSubTopic = useSelector((state) => state.filters.subtopics);
    const selectedQuestionType = useSelector((state) => state.filters.question_type);
    const selectedDifficulty = useSelector((state) => state.filters.difficulty);
    const selectedConcepts = useSelector((state) => state.filters.concepts);
    const selectedScenarios = useSelector((state) => state.filters.scenarios);

    const recover = useSelector((state) => state.rejectQuestion.removedQuestions || []);
    const allRejectedQuestions = useSelector((state) => state.rejectQuestion.removedQuestions);

    const [conceptList, setConceptList] = useState([]);
    const [scenarioList, setScenarioList] = useState([]);

    const recoverRemovedQuestions = () => {
        if (recover.length > 0) {
            dispatch(removeAllRejectedQuestions())
        }
    };

    useEffect(() => {
        if (recover.length === 0) {
            if (questions.length > 0)
                fetchQuestions(page)
        }
    }, [recover])

    useEffect(() => {
        if (activeQuiz.saved) {
            dispatch(removeActiveQuiz())
            dispatch(clearSelection({}))
        }
    }, [activeQuiz])

    const resetFilters = () => {
        dispatch(setExam(''));
        dispatch(setSubject(''));
        dispatch(setChapters([]));
        dispatch(setTopics([]));
        dispatch(setSubtopics([]));
        dispatch(setSource(''));
        dispatch(setQuestionType(''));
        dispatch(setDifficulty([]));
        dispatch(setConcepts([]));
        dispatch(setScenarios([]));

        setConceptList([]);
        setScenarioList([]);
        if (recover.length > 0) {
            dispatch(removeAllRejectedQuestions());
        }
    };

    const fetchQuestions = async (pageNumber = 0) => {
        setIsLoading(true);
        if (selectedTopic.length === 0) {
            alert("Please select a topic");
        } else {
            let queryPage = 1
            if (pageNumber === 0) {
                queryPage = 1;
            } else {
                queryPage = pageNumber
            }
            
            const payload = {
                exam: selectedExam,
                subject: selectedSubject,
                chapter: selectedChapter,
                topic: selectedTopic,
                source: selectedSource,
                subtopic: selectedSubTopic,
                questiontype: selectedQuestionType,
                difficulty: selectedDifficulty,
                concepts: selectedConcepts,
                scenarios: selectedScenarios,
                rejectedQuestions: recover,  // Add this line to include rejected question IDs
                page: queryPage,
                limit: limit
            };

            try {
                const response = await axios.get('/api/questions/fetchQuestions', {
                    params: payload,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const responseData = await response.data;
                
                setQuestions(responseData.questions);
                
                if (conceptList.length === 0) {
                    setConceptList(responseData.concepts)
                }

                if (responseData.concepts.length === 0) {  
                    setConceptList([])
                    setScenarioList([])
                    dispatch(setConcepts([]))
                    dispatch(setScenarios([]))
                }

                if (responseData.scenarios.length > 0)
                    setScenarioList(responseData.scenarios)
                
                setTotal(responseData.total);
                setPage(responseData.page);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        }
        setIsLoading(false);
    };
    
    
    const onButtonClick = async () => {
        if (recover.length > 0) {
            dispatch(removeAllRejectedQuestions())
        }
        await fetchQuestions(0)
    };

    if (isFilterLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Toolbar />
                <Toolbar />
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Box component="main" sx={{ flexGrow: 0, maxWidth: '75vw', bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <StaticFilterComponent 
                    selectedExam={selectedExam}
                    selectedSubject={selectedSubject}
                    selectedChapter={selectedChapter}
                    selectedTopic={selectedTopic}
                    selectedSource={selectedSource}
                    selectedSubTopic={selectedSubTopic}
                    selectedQuestionType={selectedQuestionType}
                    selectedDifficulty={selectedDifficulty}
                    onButtonClick={onButtonClick}
                    resetFilters={resetFilters}
                    setIsFilterLoading={setIsFilterLoading}
                    selectedConcepts={selectedConcepts}
                    conceptList={conceptList}
                    selectedScenarios={selectedScenarios}
                    scenarioList={scenarioList}
                />
                <Pagination fetchQuestions={fetchQuestions} page={page} limit={limit} total={total} />
                <Grid container className='questionContainer'>
                    {!isLoading && questions.map((questionData) => (
                        !allRejectedQuestions.includes(questionData['id']) && 
                            <Box key={questionData['id']} sx={{ width: '100%' }}>
                                <ProblemStatement 
                                    id={questionData['id']}
                                    data={questionData}
                                    displayQuestion={true}
                                    displayQuestionId={true}
                                    displaySolution={true}
                                    displayAnswerKey={true}
                                    displayOptions={true}
                                    displayAddToQuiz={true}
                                    displayRemoveFromQuiz={true}
                                    displayConcepts={true}
                                    displayRejectQuestion={true}
                                    setOpenQuizModal={setOpenQuizModal}
                                />
                                <Divider style={{ backgroundColor: 'black' }} />
                            </Box>
                    ))}
                    {isLoading && <CircularProgress />}
                </Grid>
                <Pagination fetchQuestions={fetchQuestions} page={page} limit={limit} total={total} />
            </Box>
            <Drawer
                sx={{ width: '20vw', flexShrink: 0, '& .MuiDrawer-paper': { width: '20vw', boxSizing: 'border-box' } }}
                variant="permanent"
                anchor="right"
            >
                <Toolbar />
                {
                    recover.length > 0 && 
                    <Button 
                        color="primary" 
                        size='small' 
                        sx={{
                            marginTop: '2vh',
                            marginLeft: '2vh',
                            marginRight: '2vh'
                        }}
                        variant="contained" 
                        onClick={recoverRemovedQuestions}>
                            Reset Rejected Questions ({`${recover.length}`})
                    </Button>
                }
                {/* <Button onClick={questionUpload}>Test Qestion Upload</Button> */}
                <QuizSelector openQuizModal={openQuizModal} setOpenQuizModal={setOpenQuizModal} />
                
            </Drawer>
        </Box>
    );
};

export default QuestionManager;

import React, { useState, useEffect } from 'react'
import { useParams } from "react-router"
import { useNavigate } from 'react-router-dom'
import { fetchQuizByID } from '../utils/quiz'
import { fetchQuestionById } from '../utils/question'
import { Box, Grid } from "@mui/material"

import ProblemStatement from '../components/ProblemStatement'

const PaperViewer = () => {
    const [quizData, setQuizData] = useState({})
    const [questionDetails, setQuestionDetails] = useState([])

    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        const fetchQuiz = async (token, secret) => {
            try {
                const quiz = await fetchQuizByID(token, id, secret)
                if (quiz.length > 0) {
                    setQuizData(quiz[0])
                  }
            } catch (err){
                if (err.response && err.response.status === 401)
                    navigate('/invalid')
                else
                    throw err
            }
        }
        const token = localStorage.getItem('accessToken')
        const secret = localStorage.getItem('secret')
        
        fetchQuiz(token, secret)
    }, [id])

    useEffect(() => {
        const fetchQuestions = async (token, secret) => {
            try {
                let fetchedDetails = await Promise.all(
                    quizData.questions.map((id) => fetchQuestionById({id, token, secret}))
                )
                
                fetchedDetails = fetchedDetails.sort((a, b) => a.classmarker_id - b.classmarker_id)
            

                setQuestionDetails(fetchedDetails)
            } catch (err) {
                if (err.response && err.response.status === 401)
                    navigate('/invalid')
                else
                    throw err
            }   
        }

        if (Object.entries(quizData).length > 0){ 
            const token = localStorage.getItem('accessToken')
            const secret = localStorage.getItem('secret')
            fetchQuestions(token, secret)
        }
    }, [quizData])

    return (
        <div style={{padding: '5vh'}} id='solution'>
            {
                <Grid item md={5} sm={8} xs={12}>
                {
                  questionDetails.map((questionData, index) => (
                    <Box class="solutionBox" style={{ padding: '2vh', margin: '2vh'}} key={`${questionData['id']}-solution-selected`}>
                      <ProblemStatement 
                          id={questionData['id']}
                          data={questionData}
                          key={`${questionData['id']}-question`}
                          displayQuestion={true}
                          displayQuestionId={false}
                          displaySolution={false}
                          displayAnswerKey={false}
                          displayOptions={false}
                          displayAddToQuiz={false}
                          displayRemoveFromQuiz={false}
                          removeQuizDetails={false}
                          displayConcepts={false}
                          index={index + 1}
                        />
                    </Box>
                  ))
                }
                </Grid>
            }
        </div>
    )
}

export default PaperViewer

const Options = ({options, id}) => {
    const optionMap = {
        0: 'A', 1: 'B', 2: 'C', 3: 'D'
    }

    return (
        <div style={{width:'100%'}}>
            {
                options.map((optionData) => (
                    <div style={{width:'100%'}} key={`${id}+${optionData.option_number}`} className="optionContainer">
                        {
                            optionData.option_image && optionData.option_image !== '' ?
                            <span style={{width:'100%'}} className="optionContainer">
                                {
                                    optionData.option_image !== '' ?
                                    <span style={{marginRight: '1vw'}}><b>{`${optionMap[optionData.option_number]}.`}</b></span>
                                    :
                                    <span></span>
                                }
                                <img 
                                    style={{maxWidth:'40vw',width:'90%',objectFit:'contain'}}
                                    src={optionData.option_image}
                                    alt={optionData.option_image}
                                />
                            </span> 
                            :
                            <span style={{width:'90%'}}>
                                {
                                    optionData.option_latex !== '' && 
                                    <span>
                                        <span style={{marginRight: '1vw',width:'100%'}}><b>{`${optionMap[optionData.option_number]}.`}</b></span>
                                        <span>{optionData.option_latex}</span>
                                    </span>
                                }
                                
                            </span>
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default Options
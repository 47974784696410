import axios from "axios"

export const fetchStudentList = async (token) => {
    try {
        const response = await axios.get('/api/student/fetchAll', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return response.data.data
    } catch (err) {
        console.log(err)
        return null
    }
}
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from "@mui/material";

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Toolbar } from '@mui/material'
import { useNavigate } from 'react-router'

import { fetchQuizList } from '../../utils/quiz'

const QuizList = () => {
    const [quizListDetails, setQuizListDetails] = useState([]);
    const [filteredQuizList, setFilteredQuizList] = useState([]);
    const [studentNameFilter, setStudentNameFilter] = useState('');
    const [createdByFilter, setCreatedByFilter] = useState('');

    const [subjectFilter, setSubjectFilter] = useState('');
    const [chapterFilter, setChapterFilter] = useState('');
    const [topicFilter, setTopicFilter] = useState('');
    const [difficultyFilter, setDifficultyFilter] = useState('');

    const [uniqueStudent, setUniqueStudent] = useState([]);
    const [uniqueCreatedBy, setUniqueCreatedBy] = useState([]);
    const [uniqueSubject, setUniqueSubject] = useState([])
    const [uniqueChapter,setUniqueChapter] = useState([])
    const [uniqueTopic,setTopic] = useState([])
    const [uniqueDifficulty,setUniqueDifficulty] = useState([])

    const [totalQuizzes,setTotalQuizzes] = useState('')

    const [goTo, setGoTo] = useState('');

    const [page, setPage] = useState(1); // Current page
    const limit = 10; // Number of items per page

    const token = useSelector((state) => state.user.accessToken);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuizzes = async () => {
            const quizzes = await fetchQuizList(token);
            setQuizListDetails(quizzes);
            setFilteredQuizList(quizzes); // Initialize filtered list

            const uniqueStudentNames = [...new Set(quizzes.flatMap(item => item.student_name))];
            const uniqueCreatedByNames = [...new Set(quizzes.map(item => item.created_by))];

            const uniqueSubjectName = [...new Set(quizzes.flatMap(item => item.subject))];
            const uniqueChapterName = [...new Set(quizzes.flatMap(item => item.chapter))];
            const uniqueTopicName = [...new Set(quizzes.flatMap(item => item.topic))];
            const uniqueDifficultyName = [...new Set(quizzes.flatMap(item => item.difficulty))];

            setUniqueStudent(uniqueStudentNames);
            setUniqueCreatedBy(uniqueCreatedByNames);

            setUniqueSubject(uniqueSubjectName)
            setUniqueChapter(uniqueChapterName)
            setTopic(uniqueTopicName)
            setUniqueDifficulty(uniqueDifficultyName)
        };

        fetchQuizzes();
    }, [token]);

    useEffect(() => {
        let filteredQuizzes = quizListDetails;

        if (studentNameFilter) {
            filteredQuizzes = filteredQuizzes.filter(quiz => quiz.student_name.includes(studentNameFilter));
        }

        if (createdByFilter) {
            filteredQuizzes = filteredQuizzes.filter(quiz => quiz.created_by === createdByFilter);
        }

        if(subjectFilter){
            filteredQuizzes = filteredQuizzes.filter(quiz => quiz.subject.includes(subjectFilter));
        }

        if(topicFilter){
            filteredQuizzes = filteredQuizzes.filter(quiz => quiz.topic.includes(topicFilter));
        }

        if(chapterFilter){
            filteredQuizzes = filteredQuizzes.filter(quiz => quiz.chapter.includes(chapterFilter));
        }

        if(difficultyFilter){
            filteredQuizzes = filteredQuizzes.filter(quiz => quiz.difficulty.includes(difficultyFilter));
        }

        // Apply pagination
        const startIndex = (page - 1) * limit;
        const endIndex = page * limit;
        const paginatedQuizzes = filteredQuizzes.slice(startIndex, endIndex);

        setFilteredQuizList(paginatedQuizzes);

        const totalQuizzes = quizListDetails.filter(quiz => {
            if (studentNameFilter && !quiz.student_name.includes(studentNameFilter)) return false;
            if (createdByFilter && quiz.created_by !== createdByFilter) return false;

            if (subjectFilter && !quiz.subject.includes(subjectFilter)) return false;
            if (chapterFilter && !quiz.chapter.includes(chapterFilter)) return false;
            if (topicFilter && !quiz.topic.includes(topicFilter)) return false;
            if (difficultyFilter && !quiz.difficulty.includes(difficultyFilter)) return false;
            return true;
        }).length;

        setTotalQuizzes(totalQuizzes)

    }, [studentNameFilter, createdByFilter, quizListDetails,page,subjectFilter,chapterFilter,topicFilter,difficultyFilter]);

    const totalPages = Math.ceil(totalQuizzes / limit);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
            setGoTo(''); // Clear the input field after changing the page
        }
    };

    const handleChange = () => {
        const value = parseInt(goTo, 10);
        if (!isNaN(value) && value >= 1 && value <= totalPages) {
            setPage(value);
            setGoTo(''); // Clear the input field after changing the page
        }
    };

    const viewQuiz = (quiz) => {
        navigate(`/view-quiz/${quiz.id}`);
    };

    const handleStudentNameFilter = (e) => {
        setStudentNameFilter(e.target.value);
        setPage(1);
    };

    const handleCreatedByFilter = (e) => {
        setCreatedByFilter(e.target.value);
        setPage(1);
    };

    const handleSubjectFilter = (e) => {
        setSubjectFilter(e.target.value);
        setPage(1);
    };

    const handlChapterFilter =(e) =>{
        setChapterFilter(e.target.value)
        setPage(1)
    }

    const handleTopicFilter =(e) =>{
        setTopicFilter(e.target.value)
        setPage(1)
    }

    const handleDifficultyFilter = (e) =>{
        setDifficultyFilter(e.target.value)
        setPage(1)
    }

    return (
        <TableContainer component={Paper}>
            <Toolbar />
            <div style={{ display: 'flex', justifyContent: 'center',alignItems: 'center', marginBottom: '20px'}}>
                <div style={{ margin: '10px 0', width: '100%', display: 'flex', flexDirection: 'column', paddingRight: '2vh', paddingLeft: '2vh' }}>
                    <label htmlFor="studentNameFilter" style={{ marginRight: '10px' }}>Filter by Student Name</label>
                    <select
                        id="studentNameFilter"
                        value={studentNameFilter}
                        onChange={handleStudentNameFilter}
                        style={{ padding: '5px', borderRadius: '4px' }}
                    >
                        <option value="">All</option>
                        {uniqueStudent.map((name) => (
                            <option key={name} value={name}>{name}</option>
                        ))}
                    </select>
                </div>
                <div style={{ margin: '10px 0', width: '100%', display: 'flex', flexDirection: 'column', paddingRight: '2vh'}}>
                    <label htmlFor="createdByFilter" style={{ marginRight: '10px' }}>Filter by Created Name</label>
                    <select
                        id="createdByFilter"
                        value={createdByFilter}
                        onChange={handleCreatedByFilter}
                        style={{ padding: '5px', borderRadius: '4px' }}
                    >
                        <option value="">All</option>
                        {uniqueCreatedBy.map((name) => (
                            <option key={name} value={name}>{name}</option>
                        ))}
                    </select>
                </div>
                <div style={{ margin: '10px 0', width: '100%', display: 'flex', flexDirection: 'column', paddingRight: '2vh' }}>
                    <label htmlFor="subjectFilter" style={{ marginRight: '10px' }}>Filter by Subject Name</label>
                    <select
                        id="subjectFilter"
                        value={subjectFilter}
                        onChange={handleSubjectFilter}
                        style={{ padding: '5px', borderRadius: '4px' }}
                    >
                        <option value="">All</option>
                        {uniqueSubject.map((name) => (
                            <option key={name} value={name}>{name}</option>
                        ))}
                    </select>
                </div>
                <div style={{ margin: '10px 0', width: '100%', display: 'flex', flexDirection: 'column', paddingRight: '2vh' }}>
                    <label htmlFor="chapterFilter" style={{ marginRight: '10px' }}>Filter by Chapter Name</label>
                    <select
                        id="chapterFilter"
                        value={chapterFilter}
                        onChange={handlChapterFilter}
                        style={{ padding: '5px', borderRadius: '4px' }}
                    >
                        <option value="">All</option>
                        {uniqueChapter.map((creator) => (
                            <option key={creator} value={creator}>{creator}</option>
                        ))}
                    </select>
                </div>
                <div style={{ margin: '10px 0', width: '100%', display: 'flex', flexDirection: 'column', paddingRight: '2vh' }}>
                    <label htmlFor="topicFilter" style={{ marginRight: '10px' }}>Filter by Topic</label>
                    <select
                        id="topicFilter"
                        value={topicFilter}
                        onChange={handleTopicFilter}
                        style={{ padding: '5px', borderRadius: '4px' }}
                    >
                        <option value="">All</option>
                        {uniqueTopic.map((name) => (
                            <option key={name} value={name}>{name}</option>
                        ))}
                    </select>
                </div>
                <div style={{ margin: '10px 0', width: '100%', display: 'flex', flexDirection: 'column', paddingRight: '2vh' }}>
                    <label htmlFor="difficultyFilter" style={{ marginRight: '10px' }}>Filter by Difficulty</label>
                    <select
                        id="difficultyFilter"
                        value={difficultyFilter}
                        onChange={handleDifficultyFilter}
                        style={{ padding: '5px', borderRadius: '4px' }}
                    >
                        <option value="">All</option>
                        {uniqueDifficulty.map((name) => (
                            <option key={name} value={name}>{name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div style={{ margin: '10px 0', width: '100%' }}>
                    <Box>
                        <span className='pagination'>
                            <Button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                                Previous
                            </Button>
                            <Typography variant="body2" style={{ margin: '0 10px' }}>
                                Page {page} of {totalPages}
                            </Typography>
                            <Button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                                Next
                            </Button>
                            <label htmlFor='goToPage' style={{ marginRight: '10px' }}>Jump To Page:</label>
                            <input
                                type="number"
                                id='goToPage'
                                onChange={(e) => setGoTo(e.target.value)}
                                value={goTo}
                                min={1}
                                max={totalPages}
                                style={{ width: '60px', marginLeft: '8px', padding: '4px', borderRadius: '4px', border: '1px solid #ccc' }}
                            />
                            <button onClick={handleChange}>Go</button>
                        </span>
                    </Box>
                </div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Quiz Name</TableCell>
                        <TableCell align="right">Student Name</TableCell>
                        <TableCell align="right">Subject</TableCell>
                        <TableCell align="right">Chapter</TableCell>
                        <TableCell align="center">Topic</TableCell>
                        <TableCell align="right">Questions</TableCell>
                        <TableCell align="right">Difficulty</TableCell>
                        <TableCell align="right">Created By</TableCell>
                        <TableCell align="right">Created Date</TableCell>                     
                        <TableCell align="right">Time</TableCell>
                        <TableCell align="right">View</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredQuizList.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.student_name.join(', ')}</TableCell>
                            <TableCell align="right">{row.subject?row.subject.join(', ') :"Not given" }</TableCell>
                            <TableCell align="right">{row.chapter?row.chapter.join(', ') :"Not given" }</TableCell>
                            <TableCell align="right">{row.topic?row.topic.join(', ') :"Not given" }</TableCell>
                            <TableCell align="right">{row.questions.length}</TableCell>
                            <TableCell align="right">{row.difficulty?row.difficulty.join(', ') :"Not given" }</TableCell>
                            <TableCell align="right">{row.created_by}</TableCell>
                            <TableCell align="right">{row.created_at.split('T')[0]}</TableCell>
                            <TableCell align="right">{row.max_attempt_time_in_minutes?row.max_attempt_time_in_minutes :"Not given" }</TableCell>
                            <TableCell align="right">
                                <Button onClick={() => viewQuiz(row)}>View Quiz</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default QuizList;

import axios from "axios"

export const fetchQuestionById = async ({id, token, secret='', category_id=''}) => {
    let headers = {
      Authorization: `Bearer ${token}`
    }

    if (secret !== '') {
      headers = {...headers, Secret: secret}
    }

    let params = {
      id: id
    }
    
    if (category_id !== '') {
      params = {...params, category_id: category_id}
    }
    
    const response = await axios.get(`/api/questions/fetchQuestionByID`, {
      params: params,
      headers: headers
    })
    const responseData = response.data.details
    return responseData
  }

export const uploadQuestionsToDrive = async (ids, token, source) => {
  const data = {
    questionIds: ids
  }

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    cancelToken: source.token
  }

  try {
    await axios.post(`/api/questions/upload/drive`, data, headers)
    return 'success'
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log('Request canceled:', err.message)
      return 'cancelled'
    } else {
      console.log(err)
    }
    return 'error'
  }
}

import './App.css'

import { Outlet, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, TextField } from '@mui/material'

import {validateToken , authenticateUser} from './utils/validate'

import { setAccessToken, setProfile } from './store/slice/userSlice'

function App() {
  // Access profile and accessToken from redux store.
  const profile = useSelector((state) => state.user.profile)
  const token = useSelector((state) => state.user.accessToken)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    // Validate access tokens
    const validate = async (token) => {
      try {
        const profile = await validateToken(token)
        if (profile) {
          // Set user profile to redux store.
          dispatch(setProfile(profile))
        } else {
          dispatch(setProfile({}))
          dispatch(setAccessToken(null))
        }
      } catch (err) {
        console.log(err)
        dispatch(setProfile({}))
        dispatch(setAccessToken(null))
      }
    }

    if (token) {
      validate(token)
    }
  }, [token])


  const loginUser = () => {
    if (email === '' | !email) {
      alert("ENTER EMAIL")
      return 
    }

    if (password === '' | !password) {
      alert("ENTER PASSWORD")
      return
    }

    const authenticate = async () => {
      const authtoken = await authenticateUser(token, email, password)
      if (authtoken) {
        navigate(`/callback?token=${authtoken}`)
      }
    }

    authenticate()
  }

  return (
    <div>
        {
          Object.keys(profile).length > 0 ?
            // Render Children Routes
            <Outlet /> 
          :
          <div style={{
            justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
          }}>
            <Box sx={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '20vw',
              marginTop: '10vh'
            }}>
              <img alt="logo" src='lytmus-background.png' width={225} height={150} />
            </Box>
            <Box sx={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '30vw',
              gap: '20px',
              marginTop: '10vh'
            }}>
                <TextField
                  style={{width: '100%', margin: '1vh'}} 
                  required 
                  type="email"
                  label="Email Address" 
                  value={email}
                  onChange={(event) => {
                      setEmail(event.target.value)
                  }}
                  variant="outlined"
                  size='small'
                />
                  <TextField
                  style={{width: '100%', margin: '1vh'}} 
                  required 
                  type="password"
                  label="Email Password" 
                  value={password}
                  onChange={(event) => {
                      setPassword(event.target.value)
                  }}
                  variant="outlined"
                  size='small'
                />
                <Button style={{width: '80%'}} color='primary' variant='contained' onClick={loginUser}>Sign In With Email</Button>
                <Button style={{width: '80%', marginTop: '20px'}} color='secondary' variant='contained' href='/auth/google'>Sign In With Google</Button>
            </Box>
          </div>
        }
    </div>
  )
}

export default App

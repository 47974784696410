import { createSlice } from "@reduxjs/toolkit";

export const rejectQuestionSlice = createSlice({
    name : "rejectQuestion",

    initialState : {
        removedQuestions : []
    },

    reducers:{
        rejectQuestion : (state,action)=>{
            if (!state.removedQuestions) {
                state.removedQuestions = []; // Ensure state is an array
            }

            state.removedQuestions.push(action.payload)
        },
        removeAllRejectedQuestions : (state,action)=>{
            state.removedQuestions = []
        }
    }
})

export const {rejectQuestion, removeAllRejectedQuestions} = rejectQuestionSlice.actions

export default rejectQuestionSlice.reducer
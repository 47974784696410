import { createSlice } from '@reduxjs/toolkit'

export const quizSlice = createSlice({
  name: 'quiz',
  // Configure Quiz Details
  initialState: {
    questions: [],
    activeQuiz: {}
  },
  reducers: {
    addQuizQuestion: (state, action) => {
      // Add qestion to quiz list
      state.questions.push(action.payload.id)
    },
    removeQuizQuestion: (state, action) => {
      // Remove question from quiz list
      const newState = state.questions
      state.questions =  newState.filter(value => value !== action.payload)
    },
    clearSelection: (state) => {
      // reset quiz list
      state.questions = []
    },
    bulkUpdateSelection: (state, action) => {
      // Bulk update quiz list
      state.questions = action.payload
    },
    addActiveQuiz: (state, action) => {
      state.activeQuiz = {...action.payload, saved: false}
    },
    removeActiveQuiz: (state) => {
      state.activeQuiz = {}
    },
    saveActiveQuiz: (state, action) => {
      state.activeQuiz = {...state.activeQuiz, saved:true, ...action.payload}
    },
    addQuizTime : (state,action) =>{
      state.activeQuiz.quizTime = action.payload
    }
  },
})

export const { addQuizQuestion, removeQuizQuestion, 
  clearSelection, addActiveQuiz, removeActiveQuiz, 
  saveActiveQuiz, bulkUpdateSelection, addQuizTime } = quizSlice.actions

export default quizSlice.reducer
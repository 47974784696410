import { Drawer, List, ListItem, ListItemButton, ListItemText, Paper, Toolbar, Typography, Box} from "@mui/material"

const FixedSideNavigation = ({value, setValue, meta}) => {
    return (
      <Drawer
        sx={{
          width: '20vw',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
              width: '20vw',
              boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Paper 
          sx={{
            padding: '1vh'
          }}
          elevation={0}>
          <List>
    
            <ListItem 
              sx={{
                backgroundColor: value === 0 ? '#E0E0E0' : '#F5F5F5',
              }}
              onClick={() => setValue(0)}>
              <ListItemButton>
                <ListItemText>
                  Questions
                </ListItemText>
              </ListItemButton>
            </ListItem>
            
            <ListItem 
              sx={{
                backgroundColor: value === 1 ? '#E0E0E0' : '#F5F5F5',
              }}
              onClick={() => setValue(1)}>
              <ListItemButton>
                <ListItemText>
                  Summary
                </ListItemText>
              </ListItemButton>
            </ListItem>

            <ListItem 
              sx={{
                backgroundColor: value === 2 ? '#E0E0E0' : '#F5F5F5',
              }}
              onClick={() => setValue(2)}>
              <ListItemButton>
                <ListItemText>
                  Solution
                </ListItemText>
              </ListItemButton>
              </ListItem>

              <ListItem 
              sx={{
                backgroundColor: value === 3 ? '#E0E0E0' : '#F5F5F5',
              }}
              onClick={() => setValue(3)}>
              <ListItemButton>
                <ListItemText>
                  Response
                </ListItemText>
              </ListItemButton>
              </ListItem>
          </List>
          {
            meta && Object.keys(meta).length > 0 &&
            <Box sx={{padding: '2vh'}}>
              {
                meta.classmarkerName &&
                <Box>
                  <Typography variant="caption">
                    <b>Classmarker Category <br /></b> 
                  </Typography>
                  <Typography variant="caption">
                    {meta.classmarkerName} <br/>
                  </Typography>
                  <Typography variant="caption">
                    <b>Exported At <br/></b>
                  </Typography>
                  <Typography variant="caption">
                    {meta.exporedAt}
                  </Typography>
                </Box>
              }
              {
                meta.subject.length > 0 &&
                <Box>
                  <Typography variant="caption">
                    <b>Subject <br /></b> 
                  </Typography>
                  <Typography variant="caption">
                    {meta.subject.join(',')} <br/>
                  </Typography>
                </Box>
              }
              {
                meta.difficulty.length > 0 &&
                <Box>
                  <Typography variant="caption">
                    <b>Diffculty <br /></b> 
                  </Typography>
                  <Typography variant="caption">
                    {meta.difficulty.join(',')} <br/>
                  </Typography>
                </Box>
              }
              {
                meta.topic.length > 0 &&
                <Box>
                  <Typography variant="caption">
                    <b>Topic <br /></b> 
                  </Typography>
                  <Typography variant="caption">
                    {meta.topic.join(',')} <br/>
                  </Typography>
                </Box>
              }
              {
                meta.chapter.length > 0 &&
                <Box>
                  <Typography variant="caption">
                    <b>Chapter <br /></b> 
                  </Typography>
                  <Typography variant="caption">
                    {meta.chapter.join(',')} <br/>
                  </Typography>
                </Box>
              }
              {
                meta.max_attempt_time_in_minutes &&
                <Box>
                  <Typography variant="caption">
                    <b>Max Quiz Time <br /></b> 
                  </Typography>
                  <Typography variant="caption">
                    {meta.max_attempt_time_in_minutes} minutes<br/>
                  </Typography>
                </Box>
              }
            </Box>
          }
        </Paper>
      </Drawer>
    )
}

export default FixedSideNavigation
import axios from "axios"

const validateToken = async (token) => {
    try {
        const response = await axios.get(`/api/users/validate`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return response.data
    } catch {
        return null
    }
}

const authenticateUser = async (token, email, password) => {
    try {
        const response = await axios.post(`/auth/email`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                email,
                password
            }
        })
        return response.data.token
    } catch {
        alert("ERROR")
    }
}


export { validateToken, authenticateUser }
import { createBrowserRouter } from 'react-router-dom'

import Home from './Home'
import Quiz from './Quiz'
import NotFound from './NotFound'
import ProcessLogin from './ProcessLogin'

import QuizViewer from './subroutes/QuizViewer'
import QuizCreator from './subroutes/QuizCreator'
import QuizList from './subroutes/QuizList'
import QuestionManager from './subroutes/QuestionManager'

import App from '../App'
import QuestionViewer from './QuestionViewer'
import SolutionViewer from './SolutionViewer'
import PaperViewer from './PaperViewer'

export const router = createBrowserRouter([
    {
      path: '/',
      element: <App />,
      children: [
        { 
          path: '/',  
          element: <Home /> ,
          children: [
            {
              // Search and view questions 
              path: '/',
              element: <QuestionManager />,
            },
            {
              // Review and save quiz 
              path: '/review-quiz',
              element: <QuizCreator />
            },
            {
              // View quiz   
              path: '/view-quiz/:id',
              element: <QuizViewer/>
            },
            {
              // View list of quizzez
              path: '/quizzes',
              element: <QuizList />
            }
          ]
        }
      ],
    },
    {
      path: '/view-question/:id',
      element: <QuestionViewer />
    },
    {
      path: '/view-solution/:id',
      element: <SolutionViewer />
    },
    {
      path: '/view-quiz-paper/:id',
      element: <PaperViewer />
    },
    {
      // Process auth callback, validate access-token 
      path: '/callback',
      element: <ProcessLogin />
    },
    {
      // Placeholder - For in-app quiz
      path: '/quiz/:id',
      element: <Quiz />
    },
    {
      // Catch invalid route
      path: '/invalid',
      element: <NotFound />
    },
    {
      // Catch invalid route 
      path: '*',
      element: <NotFound />
    }
])
import axios from "axios"

export const fetchQuizList = async (token) => {
    try {
        const response = await axios.get('/api/quiz/fetchAll', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return response.data.data
    } catch (err) {
        console.log(err)
        return null
    }
}

export const fetchQuizByID = async (token, id, secret='') => {
    try {
        let headers = {
            Authorization: `Bearer ${token}`
        }
    
        if (secret !== '') {
            headers = {...headers, Secret: secret}
        }

        const response = await axios.get('/api/quiz/fetch', {
            params: {
                id: id
            },
            headers: headers
        })

        return response.data.data
    } catch (err) {
        console.log(err)
        return null
    }
}

export const fetchQuizByStudentID = async (token, id) => {
    try {
        const response = await axios.get('/api/quiz/fetch/student', {
            params: {
                id: id
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return response.data.data
    } catch (err) {
        console.log(err)
        return null
    }
}

export const exportQuestionsToClassMarker = async (questions, students, subject, quizName, quizId, token, source) => {
    const data = {
        questions,
        students,
        quizName,
        quizId,
        subject
    }
  
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      cancelToken: source.token
    }
  
    try {
      await axios.post(`/api/quiz/export/classmarker`, data, headers)
      return 'success'
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('Request canceled:', err.message)
        return 'cancelled'
      } else {
        console.log(err)
      }
      return 'error'
    }
  }

export const markQuizAsExported = async (id, token) => {
    try {
        const data = {
            quizId: id
        }
        
        const headers = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await axios.post('/api/quiz/mark/exported', data, headers)
        return response.data.data
    } catch (err) {
        console.log(err)
        return []
    }
}

export const downloadQuizSolution = async (token, id, name) => {
    try {
        const response = await axios.get('/api/quiz/download/solution', {
            params: {
                quizId: id,
                quizName: name
            },
            headers: {
                Authorization: `Bearer ${token}`
            },
            responseType: 'blob'
        })

        // Create a URL for the Blob and set up a download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}_solution.pdf`); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.remove();

    } catch (err) {
        console.log(err)
        return null
    }
}

export const downloadQuizPaper = async (token, id, name) => {
    try {
        const response = await axios.get('/api/quiz/download/paper', {
            params: {
                quizId: id,
                quizName: name
            },
            headers: {
                Authorization: `Bearer ${token}`
            },
            responseType: 'blob'
        })

        // Create a URL for the Blob and set up a download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}_dpp.pdf`); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.remove();

    } catch (err) {
        console.log(err)
        return null
    }
}
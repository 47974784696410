import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { combineReducers } from 'redux'
import userSlice from './slice/userSlice'
import filterSlice from './slice/filterSlice'
import quizSlice from './slice/quizSlice'
import rejectSlice from './slice/rejectSlice'

// Configure persist reducer
const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  user: userSlice,
  filters: filterSlice,
  quiz: quizSlice,
  rejectQuestion : rejectSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const reduxstore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(reduxstore)
export const store = reduxstore

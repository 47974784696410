import React, { useState, useEffect } from 'react'
import { useParams } from "react-router"
import { useNavigate } from 'react-router-dom'
import { fetchQuestionById } from '../utils/question'

import Question from '../components/problemStatementComponents/Question'
import Options from '../components/problemStatementComponents/Options'

const QuestionViewer = () => {
    const [question, setQuestion] = useState({})
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        const fetchQuestion = async (token, secret) => {
            try {
                const question = await fetchQuestionById({id, token, secret})
                if (question) {
                    setQuestion(question)
                }
            } catch (err){
                if (err.response.status === 401)
                    navigate('/invalid')
            }
        }
        const token = localStorage.getItem('accessToken')
        const secret = localStorage.getItem('secret')
        
        fetchQuestion(token, secret)
    }, [id])

    return (
        <div>
            {
                Object.keys(question).length > 0 &&
                <div id="question" style={{padding: '5vh'}}>
                    <Question data={question}/>
                    <Options options={question.options} id={question.id}/>
                </div>
            }
        </div>
    )
}

export default QuestionViewer
